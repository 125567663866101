.footer{
    width: 100%;
    background-color: #38B7FF;
    height: 10rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.footer li{
    list-style-type: none;
    text-decoration: none;
}
.footer a:visited{
   color: palegreen;
}