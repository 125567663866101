.res-navbar{
    margin-top: 30px;
}
.res-navbar.open{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.logo-account.open{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}
.logo-account{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
.res-links{
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
}
.navbar-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #eee;
    padding: 1rem;
  }
  
  .navbar-menu.open {
    display: flex;
  }
  .navbar-item {
    margin-bottom: 1.7rem;
    list-style-type: none;
  }  
  .navbar-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0.5rem;
    cursor: pointer;
    padding: 2rem;
  }
  .navbar-toggle.open {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0.5rem;
    cursor: pointer;
    padding: 2rem;
    position: absolute;
    top: 23px;
    right: 64px;
  }
  .navbar-toggle li{
    list-style-type: none;
    margin-top: 8px;
}

  .navbar-icon {
    display: block;

    height: 3px;
    width: 19px;
    background-color: #38B7FF;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  
  .navbar-icon:nth-child(2) {
    transform-origin: center;
  }
  
  .navbar-toggle.open .navbar-icon:nth-child(1) {
    transform: rotate(45deg);
  }
  
  .navbar-toggle.open .navbar-icon:nth-child(2) {
    transform: rotate(-45deg);
  }
  
  .navbar-toggle.open .navbar-icon:nth-child(3) {
    transform: scaleX(0);
  }