.trip-details-and-invite{
    margin: auto;
}
.header-step{
    text-align: center;
}
.trip{
    margin: 50px 0 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
.form{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: auto;
}
.box{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: auto;
}
@media screen and (max-width: 829px){
    .trip {
        margin: 50px 0 60px 0;
        display: flex;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        justify-content: center;
        flex-direction: column-reverse;
        width: 100%;
    }
   
}