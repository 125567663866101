.box{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 400px;
}

.invited_friendsbox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    height: 400px;
    padding-top: 20px;;
}
.list-items li{
    list-style: none;
}
@media screen and (max-width: 829px){
    .box {
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        width: 80%;
        margin-top: 33px;
    }
}