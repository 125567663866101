.active{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
@media screen and (min-width: 830px){
    .active_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 3rem;
}
}

@media screen and (max-width: 829px){
    .active_grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 10px;
        margin-top: 3rem;
    }
}