.summary{
    height: 200px;
    width: 400px;
   overflow-y:scroll;
   padding: 20px 10px 10px; /* set top padding to move text cursor to the top */

}
#invite-input{
    margin-top: 95px;
}
#submit-form-invite{
    margin-top: 30px;
    padding: 10px;
}
#inputs:focus-visible{
        outline: 3px solid #38B7FF;
}
@media screen and (max-width: 829px){
.summary {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    padding: 20px 10px 10px;
}
}