@media screen and (min-width: 830px){
    .navbar{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 30px;
        width: 100%;
        
    }
    .links{
        display: flex;
        flex-direction: row;
        margin: auto;
    }
    .navbar li{
        list-style-type: none;
        margin-left: 30px;
    }
    .navbar a:hover{
        list-style-type: none;
        color: #38B7FF;
    }
    .account-area{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 69px;
        padding:5px;
        text-align: center;
    }
    .sign_up_link{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 120px;
    }
    .user-sign-img{
        width: 35px;
    }
}

@media screen and (max-width: 829px) {
    .navbar{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 30px;
        width: 100%;
        height: 22rem;
    }
    .links{
        display: flex;
        flex-direction: column;
        margin: auto;
        text-align: center;
    }
    .navbar li{
        list-style-type: none;
        margin-top: 30px;
    }
    .navbar a:hover{
        list-style-type: none;
        color: #38B7FF;
    }
    .account-area{
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        border-radius: 69px;
        padding: 5px;
        text-align: center;
        margin-top: 35px;
    }
    .sign_up_link{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 120px;
    }
    .user-sign-img{
        width: 35px;
    }
}