@media screen and (min-width: 829px) {
.ReservedTripBox{
    width: 30rem;
    height: 30rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.res-btn{
    width: 50%;
    height: 3rem;
    border-radius: 12px;
    border: none;
    color: white;
    background-color: #38B7FF;
}
}

@media screen and (max-width: 829px) {
    .ReservedTripBox{
        width: 100%;
        height: 30rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .res-btn{
        width: 50%;
        height: 3rem;
        border-radius: 12px;
        border: none;
        color: white;
        background-color: #38B7FF;
    }
}
