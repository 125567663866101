.a_flex{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.main_pic {
    height: 40.33rem;
    width: 50rem;
    margin-right: 5px;
}
#img1 {
    width: 100%;
    height: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.four_pic_grid {
    display: grid;
    grid-template-columns: 20rem 20rem;
    grid-template-rows: 20rem 20rem;
    gap: 5px;
    height: 100%;
}
#img2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    height: 100%;
}
#img3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    height: 100%;
    border-top-right-radius: 12px;
}
#img4 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    width: 100%;
    height: 100%;
}
#img5 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 12px;
}
@media screen and (max-width: 829px) {
    .a_flex {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    .main_pic {
        height: 20.33rem;
        width: 100%;
        margin-right: 5px;
    }
    #img1 {
        width: 100%;
        height: 100%;
    }
    #img2 {
        width: 100%;
        height: 100%;
    }
    #img3 {
        width: 100%;
        height: 100%;
    }
    #img4 {
        width: 100%;
        height: 100%;
    }
    #img5 {
        width: 100%;
        height: 100%;
    }
    .four_pic_grid {
        display: flex;
        gap: 5px;
        height: 100%;
        flex-direction: column;
        width: 100%;
    }
}