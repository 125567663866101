.section_one{
    width: 70rem;
    height: 30rem;
}
.details{
    margin-top: 40px;
}
.paragraph{
    margin-top: 40px;
    width: 50%;
}
@media screen and (max-width: 829px) {
    .section_one {
        height: 30rem;
        width: 100%;
        padding: 10px;
    }
   .section_one h1 {
        font-size: 29px;
    }
    .paragraph{
        margin-top: 40px;
        width: 100%;
    }
    
}